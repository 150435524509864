<template>
  <Skeleton v-if="loading"
width="100" height="72" />
  <navbar-detail-donasi v-else
@click="$router.go(-1)">
    <div class="navbar-brand">
      <svg
        width="22"
        height="22"
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M21.6666 9.66667V12.3333H5.66663L13 19.6667L11.1066 21.56L0.546631 11L11.1066 0.440002L13 2.33334L5.66663 9.66667H21.6666Z"
          fill="#F2F2F2"
        />
      </svg>
    </div>
    <h6 class="title-navbar">
      Donasi Saya
    </h6>
  </navbar-detail-donasi>
  <section class="container section section__detail-donasi">
    <div class="card card__detail-donasi">
      <div class="card-body">
        <Skeleton v-if="loading"
width="60" height="20" style="margin:auto;" />
        <div v-else>
          <h5
            v-if="dataPayment.transactionStatus === 'waiting'"
            class="card-title card__title-detail"
          >
            Tinggal Sedikit Lagi
          </h5>
          <h5
            v-else-if="dataPayment.transactionStatus === 'verifying'"
            class="card-title card__title-detail"
          >
            Proses Verifikasi
          </h5>
          <h5
            v-else-if="dataPayment.transactionStatus === 'done'"
            class="card-title card__title-detail"
          >
            Berhasil
          </h5>
        </div>
        <Skeleton v-if="loading"
width="80" height="19" class="mt-2" style="margin:auto;" />
        <div v-else>
          <p v-if="dataPayment.transactionStatus === 'waiting'"
class="card-text card__text-detail">
            Lakukan pembayaran untuk melanjutkan donasi
          </p>
          <p
            v-else-if="dataPayment.transactionStatus === 'verifying'"
            class="card-text card__text-detail"
          >
            Pembayaran sedang diverfikasi
          </p>
          <p
            v-else-if="dataPayment.transactionStatus === 'done'"
            class="card-text card__text-detail"
          >
            Pembayaran sudah berhasil
          </p>
        </div>

        <Skeleton v-if="loading"
width="80" height="20" class="mt-3" />
        <Skeleton v-if="loading"
width="60" height="20" class="mt-2" />
        <p v-else
class="card__text-campaign">
          {{ dataPayment.wakafName }}
        </p>

        <div class="detail__donation-wrapper">
          <div class="information__detail-donation">
            <div class="information__wrapper">
              <Skeleton v-if="loading"
width="111px" height="18" />
              <span v-else
class="title__information">Nominal</span>
              <Skeleton v-if="loading"
width="111px" height="18" />
              <span v-else class="value__information"
                >Rp. {{ $formatPrice(dataPayment.total) }}</span
              >
            </div>
            <div class="information__wrapper">
              <Skeleton v-if="loading" width="111px" height="18" />
              <span v-else class="title__information">Tanggal</span>
              <Skeleton v-if="loading" width="111px" height="18" />
              <span v-else class="value__information">{{ dataPayment.transactionDate }}</span>
            </div>
            <div class="information__wrapper">
              <Skeleton v-if="loading" width="111px" height="18" />
              <span v-else class="title__information">Tanggal Jatuh Tempo</span>
              <Skeleton v-if="loading" width="111px" height="18" />
              <span v-else class="value__information">{{ dataPayment.transactionDueDate }}</span>
            </div>
            <div class="information__wrapper align-items-center">
              <Skeleton v-if="loading" width="111px" height="18" />
              <span v-else class="title__information">Metode Pembayaran</span>
              <Skeleton v-if="loading" width="111px" height="18" />
              <div v-else class="text-end">
                <img
                  v-if="dataPayment.iconBank !== null"
                  :src="dataPayment.iconBank"
                  alt="Icon Payment"
                  class="icon__information"
                />
                <p class="value__information mb-0">
                  {{ dataPayment.nameBank ?? '-' }} - A/n {{ dataPayment.ownerName }}
                </p>
              </div>
            </div>
            <div class="information__wrapper">
              <Skeleton v-if="loading"
width="111px" height="18" />
              <span v-else
class="title__information">ID Donasi</span>
              <Skeleton v-if="loading"
width="111px" height="18" />
              <span v-else
class="value__information">{{ dataPayment.transactionCode }}</span>
            </div>
            <div class="information__wrapper">
              <Skeleton v-if="loading"
width="111px" height="18" />
              <span v-else
class="title__information">Status</span>
              <Skeleton v-if="loading"
width="111px" height="18" />
              <div v-else>
                <span class="value__information" v-if="dataPayment.transactionStatus == 'waiting'"
                  >Menunggu</span
                >
                <span
                  v-else-if="dataPayment.transactionStatus == 'verifying'"
                  class="value__information"
                  >Proses Verifikasi</span
                >
                <span class="value__information" v-else-if="dataPayment.transactionStatus == 'done'"
                  >Berhasil</span
                >
                <span
                  v-else-if="dataPayment.transactionStatus == 'failed'"
                  class="value__information"
                  >Gagal</span
                >
              </div>
            </div>
          </div>
        </div>

        <!-- <router-link
          v-if="
            (!loading && dataPayment.transactionStatus === 'waiting') ||
              dataPayment.transactionStatus === 'verifying'
          "
          :to="`/intruksi-pembayaran/${dataPayment.transactionCode}`"
          type="submit"
          class="btn btn-sm btn-warning login-button w-100 d-flex align-items-center justify-content-center"
        >
          Intruksi Pembayaran
        </router-link> -->
        <button
          v-if="
            (!loading && dataPayment.transactionStatus === 'waiting') ||
              dataPayment.transactionStatus === 'verifying'
          "
          type="button"
          class="btn btn-sm btn-warning login-button w-100 d-flex align-items-center justify-content-center"
          @click="$router.push(`/intruksi-pembayaran/${dataPayment.transactionCode}`)"
        >
          Intruksi pembayaran
        </button>
        <!-- <a
          v-if="!loading && dataPayment.transactionStatus === 'waiting'"
          href="#"
          class="btn btn-sm btn-light register-button d-flex align-items-center justify-content-center w-100"
          @click="handleVerifyingDonation(dataPayment.transactionCode)"
        >
          Sudah Bayar
        </a> -->
      </div>
    </div>
  </section>
</template>

<script>
import swal from 'sweetalert2'

import Skeleton from '../components/Skeleton/Skeleton.vue'
import NavbarDetailDonasi from '../components/TransactionComponent/NavbarTransaction.vue'

export default {
  components: { NavbarDetailDonasi, Skeleton },
  data() {
    return {
      accessToken: null,
      dataPayment: {
        id: null,
        wakafName: null,
        nameBank: null,
        iconBank: null,
        code: null,
        gate: null,
        accountNumber: null,
        transactionDueDate: null,
        qrisUrl: null
      },
      loading: true
    }
  },
  mounted() {
    this.isAccessToke()
    this.getDetailDonasi()
  },
  methods: {
    isAccessToke() {
      if (process.browser) {
        if (localStorage.getItem('access_token')) {
          this.accessToken = localStorage.getItem('access_token')
        }
      }
    },
    getDetailDonasi() {
      const transactionCode = this.$route.params.code
      this.$http
        .get(`${process.env.VUE_APP_API_URL}donate/${transactionCode}`, {
          headers: {
            Authorization: `Bearer ${this.accessToken}`
          }
        })
        .then(response => {
          const data = response.data
          const paymentChannelId = data.payment_channel_id
          this.getCampaign(data.wakaf_id)

          if (data.payment_gateway === 'tripay') {
            if (paymentChannelId === '13') {
              this.dataPayment = {
                id: paymentChannelId,
                nameBank: 'BNI Virtual Account',
                iconBank: require('../assets/svg/icon-bni-bank.svg'),
                code: 'BNIVA',
                gate: data.payment_gateway,
                qrisUrl: data.payment_qr_url,
                total: data.total,
                accountNumber: data.payment_code,
                transactionCode: data.transaction_code,
                transactionDate: this.$moment(data.transaction_date)
                  .locale('id')
                  .format('LLLL'),
                transactionDueDate: data.transaction_due_date ? this.$moment(data.transaction_due_date)
                  .locale('id')
                  .format('LLLL') : '-',
                transactionStatus: data.transaction_status
              }
            } else if (paymentChannelId === '12') {
              this.dataPayment = {
                id: paymentChannelId,
                nameBank: 'Permata Virtual Account',
                iconBank: require('../assets/svg/icon-permata-bank.svg'),
                code: 'PERMATAVA',
                gate: data.payment_gateway,
                qrisUrl: data.payment_qr_url,
                accountNumber: data.payment_code,
                total: data.total,
                transactionCode: data.transaction_code,
                transactionDate: this.$moment(data.transaction_date)
                  .locale('id')
                  .format('LLLL'),
                transactionDueDate: data.transaction_due_date ? this.$moment(data.transaction_due_date)
                  .locale('id')
                  .format('LLLL') : '-',
                transactionStatus: data.transaction_status
              }
            } else if (paymentChannelId === '14') {
              this.dataPayment = {
                id: paymentChannelId,
                nameBank: 'BRI Virtual Account',
                iconBank: require('../assets/svg/icon-bri-bank.svg'),
                code: 'BRIVA',
                gate: data.payment_gateway,
                qrisUrl: data.payment_qr_url,
                accountNumber: data.payment_code,
                total: data.total,
                transactionCode: data.transaction_code,
                transactionDate: this.$moment(data.transaction_date)
                  .locale('id')
                  .format('LLLL'),
                transactionDueDate: data.transaction_due_date ? this.$moment(data.transaction_due_date)
                  .locale('id')
                  .format('LLLL') : '-',
                transactionStatus: data.transaction_status
              }
            } else if (paymentChannelId === '15') {
              this.dataPayment = {
                id: paymentChannelId,
                nameBank: 'Mandiri Virtual Account',
                iconBank: require('../assets/svg/icon-mandiri-bank.svg'),
                code: 'MANDIRIVA',
                gate: data.payment_gateway,
                qrisUrl: data.payment_qr_url,
                accountNumber: data.payment_code,
                total: data.total,
                transactionCode: data.transaction_code,
                transactionDate: this.$moment(data.transaction_date)
                  .locale('id')
                  .format('LLLL'),
                transactionDueDate: data.transaction_due_date ? this.$moment(data.transaction_due_date)
                  .locale('id')
                  .format('LLLL') : '-',
                transactionStatus: data.transaction_status
              }
            } else if (paymentChannelId === '16') {
              this.dataPayment = {
                id: paymentChannelId,
                nameBank: 'BCA Virtual Account',
                iconBank: require('../assets/svg/icon-bca-bank.svg'),
                code: 'BCAVA',
                gate: data.payment_gateway,
                qrisUrl: data.payment_qr_url,
                accountNumber: data.payment_code,
                total: data.total,
                transactionCode: data.transaction_code,
                transactionDate: this.$moment(data.transaction_date)
                  .locale('id')
                  .format('LLLL'),
                transactionDueDate: data.transaction_due_date ? this.$moment(data.transaction_due_date)
                  .locale('id')
                  .format('LLLL') : '-',
                transactionStatus: data.transaction_status
              }
            } else if (paymentChannelId === '17') {
              this.dataPayment = {
                id: paymentChannelId,
                nameBank: 'Alfamart',
                iconBank: require('../assets/svg/icon-alfamart.svg'),
                code: 'ALFAMART',
                gate: data.payment_gateway,
                qrisUrl: data.payment_qr_url,
                accountNumber: data.payment_code,
                total: data.total,
                transactionCode: data.transaction_code,
                transactionDate: this.$moment(data.transaction_date)
                  .locale('id')
                  .format('LLLL'),
                transactionDueDate: data.transaction_due_date ? this.$moment(data.transaction_due_date)
                  .locale('id')
                  .format('LLLL') : '-',
                transactionStatus: data.transaction_status
              }
            } else if (paymentChannelId === '18') {
              this.dataPayment = {
                id: paymentChannelId,
                nameBank: 'QRIS',
                iconBank: require('../assets/svg/icon-qris.svg'),
                code: 'qris',
                gate: data.payment_gateway,
                accountNumber: data.payment_code,
                qrisUrl: data.payment_qr_url,
                total: data.total,
                transactionCode: data.transaction_code,
                transactionDate: this.$moment(data.transaction_date)
                  .locale('id')
                  .format('LLLL'),
                transactionDueDate: data.transaction_due_date ? this.$moment(data.transaction_due_date)
                  .locale('id')
                  .format('LLLL') : '-',
                transactionStatus: data.transaction_status
              }
            }
          } else if (data.payment_gateway === 'midtrans') {
            if (paymentChannelId == '10') {
              this.dataPayment = {
                id: paymentChannelId,
                nameBank: 'Gopay',
                iconBank: require('../assets/svg/gopay-seeklogo.com-5.svg'),
                code: 'gopay',
                total: data.total,
                transactionCode: data.transaction_code,
                transactionDate: this.$moment(data.transaction_date)
                  .locale('id')
                  .format('LLLL'),
                accountNumber: null,
                transactionDueDate: data.transaction_due_date ? this.$moment(data.transaction_due_date)
                  .locale('id')
                  .format('LLLL') : '-',
                transactionStatus: data.transaction_status
              }
            } else if (paymentChannelId == '11') {
              this.dataPayment = {
                id: paymentChannelId,
                nameBank: 'QRIS',
                iconBank: null,
                code: 'qris',
                total: data.total,
                transactionCode: data.transaction_code,
                transactionDate: this.$moment(data.transaction_date)
                  .locale('id')
                  .format('LLLL'),
                accountNumber: null,
                transactionDueDate: data.transaction_due_date ? this.$moment(data.transaction_due_date)
                  .locale('id')
                  .format('LLLL') : '-',
                transactionStatus: data.transaction_status
              }
            } else {
              this.dataPayment = {
                id: paymentChannelId,
                nameBank: null,
                iconBank: null,
                code: null,
                total: data.total,
                transactionCode: data.transaction_code,
                transactionDate: this.$moment(data.transaction_date)
                  .locale('id')
                  .format('LLLL'),
                accountNumber: null,
                transactionDueDate: data.transaction_due_date ? this.$moment(data.transaction_due_date)
                  .locale('id')
                  .format('LLLL') : '-',
                transactionStatus: data.transaction_status
              }
            }
          } else {
            if (paymentChannelId == '21') {
              this.dataPayment = {
                id: paymentChannelId,
                nameBank: 'Transfer Bank Syariah Mandiri',
                iconBank: require('../assets/logo_bsi.png'),
                code: 'bsi_az_account',
                gate: data.payment_gateway,
                qrisUrl: data.payment_qr_url,
                total: data.total,
                transactionCode: data.transaction_code,
                transactionDate: this.$moment(data.transaction_date)
                  .locale('id')
                  .format('LLLL'),
                accountNumber: '3000202206',
                transactionDueDate: data.transaction_due_date ? this.$moment(data.transaction_due_date)
                  .locale('id')
                  .format('LLLL') : '-',
                transactionStatus: data.transaction_status,
                ownerName: 'Yayasan Amazing Sedekah Indonesia'
              }
            } else if (paymentChannelId === '02') {
              this.dataPayment = {
                id: paymentChannelId,
                nameBank: 'Transfer CIMB Niaga Syariah',
                iconBank: require('../assets/svg/icon-cimb-syariah-bank.svg'),
                code: 'cimb_syariah_account',
                gate: data.payment_gateway,
                qrisUrl: data.payment_qr_url,
                accountNumber: '762369272300',
                total: data.total,
                transactionCode: data.transaction_code,
                transactionDate: this.$moment(data.transaction_date)
                  .locale('id')
                  .format('LLLL'),
                transactionDueDate: data.transaction_due_date ? this.$moment(data.transaction_due_date)
                  .locale('id')
                  .format('LLLL') : '-',
                transactionStatus: data.transaction_status,
                ownerName: 'Yayasan Amazing Sedekah Indonesia'
              }
            } else if (paymentChannelId == '22') {
              this.dataPayment = {
                id: paymentChannelId,
                nameBank: 'Transfer BRI',
                iconBank: require('../assets/svg/icon-bri-bank.svg'),
                code: 'bri_az_account',
                gate: data.payment_gateway,
                qrisUrl: data.payment_qr_url,
                total: data.total,
                transactionCode: data.transaction_code,
                transactionDate: this.$moment(data.transaction_date)
                  .locale('id')
                  .format('LLLL'),
                accountNumber: '033001000882566',
                transactionDueDate: data.transaction_due_date ? this.$moment(data.transaction_due_date)
                  .locale('id')
                  .format('LLLL') : '-',
                transactionStatus: data.transaction_status,
                ownerName: 'Yayasan Amazing Sedekah Indonesia'
              }
            } else if (paymentChannelId == '23') {
              this.dataPayment = {
                id: paymentChannelId,
                nameBank: 'Transfer Mandiri',
                iconBank: require('../assets/svg/icon-mandiri-bank.svg'),
                code: 'mandiri_az_account',
                gate: data.payment_gateway,
                qrisUrl: data.payment_qr_url,
                total: data.total,
                transactionCode: data.transaction_code,
                transactionDate: this.$moment(data.transaction_date)
                  .locale('id')
                  .format('LLLL'),
                accountNumber: '1270028092029',
                transactionDueDate: data.transaction_due_date ? this.$moment(data.transaction_due_date)
                  .locale('id')
                  .format('LLLL') : '-',
                transactionStatus: data.transaction_status,
                ownerName: 'Yayasan Amazing Sedekah Indonesia'
              }
            } else if (paymentChannelId === '03') {
              this.dataPayment = {
                id: paymentChannelId,
                nameBank: 'Transfer BCA',
                iconBank: require('../assets/svg/icon-bca-bank.svg'),
                code: 'bca_account',
                gate: data.payment_gateway,
                qrisUrl: data.payment_qr_url,
                accountNumber: '5865321225',
                total: data.total,
                transactionCode: data.transaction_code,
                transactionDate: this.$moment(data.transaction_date)
                  .locale('id')
                  .format('LLLL'),
                transactionDueDate: data.transaction_due_date ? this.$moment(data.transaction_due_date)
                  .locale('id')
                  .format('LLLL') : '-',
                transactionStatus: data.transaction_status,
                ownerName: 'Nanu Utama'
              }
            } else if (paymentChannelId === '04') {
              this.dataPayment = {
                id: paymentChannelId,
                nameBank: 'Transfer BRI',
                iconBank: require('../assets/svg/icon-bri-bank.svg'),
                code: 'bri_account',
                gate: data.payment_gateway,
                qrisUrl: data.payment_qr_url,
                accountNumber: '7317-01-004937-50-0',
                total: data.total,
                transactionCode: data.transaction_code,
                transactionDate: this.$moment(data.transaction_date)
                  .locale('id')
                  .format('LLLL'),
                transactionDueDate: data.transaction_due_date ? this.$moment(data.transaction_due_date)
                  .locale('id')
                  .format('LLLL') : '-',
                transactionStatus: data.transaction_status,
                ownerName: 'Nanu Utama'
              }
            } else if (paymentChannelId === '05') {
              this.dataPayment = {
                id: paymentChannelId,
                nameBank: 'Transfer Mandiri',
                iconBank: require('../assets/svg/icon-mandiri-bank.svg'),
                code: 'mandiri_account',
                gate: data.payment_gateway,
                qrisUrl: data.payment_qr_url,
                accountNumber: '127-00-1063427-5',
                total: data.total,
                transactionCode: data.transaction_code,
                transactionDate: this.$moment(data.transaction_date)
                  .locale('id')
                  .format('LLLL'),
                transactionDueDate: data.transaction_due_date ? this.$moment(data.transaction_due_date)
                  .locale('id')
                  .format('LLLL') : '-',
                transactionStatus: data.transaction_status,
                ownerName: 'Nanu Utama'
              }
            }
          }
        })
        .catch(error => {
          //   console.log(error.response)
        })
    },
    getCampaign(wakafId) {
      this.$http.get(`${process.env.VUE_APP_API_URL}campaigns/${wakafId}`).then(response => {
        this.dataPayment.wakafName = response.data.wakaf_name
        this.loading = false
      })
    },
    handleVerifyingDonation(transactionCode) {
      swal.fire({
        title: '', // add html attribute if you want or remove
        text: 'Mohon Menunggu',
        allowOutsideClick: false,
        showConfirmButton: false,
        didOpen: function() {
          swal.showLoading()
        }
      })
      this.$http
        .post(`${process.env.VUE_APP_API_URL}donate/${transactionCode}/verifying`)
        .then(response => {
          swal.close()
          swal.hideLoading()
          this.dataPayment.transactionStatus = response.data.transaction_status
          swal.fire('Berhasil', 'Donasi kamu berhasil terverifikasi', 'success').then(result => {
            if (result.isConfirmed) {
              this.$router.push({ path: '/detail-donasi' })
            }
          })
        })
        .catch(error => {
          //   console.log(error.response)
          swal.close()
          swal.hideLoading()
        })
    }
  }
}
</script>
